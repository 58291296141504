import gsap from "gsap"
import { ScrollToPlugin } from "gsap/all"
import React from "react"
import { useState, useRef, useEffect } from "react"
import { isMobile } from "react-device-detect"
import { maintainAspectRatio } from "../../utils/video"
import { localiseString } from "../../utils/localiseString"

import { EpisodesCarouselItem } from "../episodesCarouselItem"
import ScrollSection from "../locomotiveScroll/scrollSection"
import Slider from "react-slick"

// Styles
import styles from "./episodes.module.scss"
import Notify from "../notify"
import Portal from "../portal"

gsap.registerPlugin(ScrollToPlugin)

const EpisodesCarousel = ({ episodes, bonus }) => {
  const seasonValues = episodes.map(episode => episode.seasonNumber)
  const seasons = [...new Set(seasonValues)].sort()
  const seasonsLength = seasons.length
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 })
  const [showNotify, setShowNotify] = useState(false)
  const [activeSeason, _setActiveSeason] = useState(0) // Start on latest season
  const [activeSeasonTab, setActiveSeasonTab] = useState(0) // Start on latest season
  const [tabsAreSticky, setTabsAreSticky] = useState(false)
  const activeSeasonRef = useRef(activeSeason)
  const tabsActiveBarRef = useRef(null)
  const seasonRefs = useRef([])
  const sliderRefs = useRef([])
  const episodeRefs = useRef([])
  const episodesContainerRef = useRef([])
  const fixTabsRef = useRef(false)
  const sliderInitBreakpoint = 1023
  let seasonEpisodes = []
  let pageScrolled = false
  let scrollTicking = false
  let scrollRAF = false
  let header

  const setActiveSeason = value => {
    _setActiveSeason(value)
    activeSeasonRef.current = value
  }

  const filterEpisodes = (() => {
    for (let seasonIndex = 0; seasonIndex < seasonsLength; seasonIndex++) {
      seasonEpisodes.push([])
      episodeRefs.current.push([])
    }

    // Push 1 extra for bonus episodes to use
    if (bonus.length) episodeRefs.current.push([])

    seasonEpisodes = episodes.reduce((epArr, episode) => {
      if (!episode.heroPosterImage) {
        return epArr
      }
      if (episode.seasonNumber > 0) {
        epArr[episode.seasonNumber - 1].push(episode)
        return epArr
      }
    }, seasonEpisodes)

    seasons.reverse()
    seasonEpisodes.reverse()

    // We need to rmove any seasons with no episodes in them
    for (let seasonIndex = 0; seasonIndex < seasonsLength; seasonIndex++) {
      if (seasonEpisodes[seasonIndex] && seasonEpisodes[seasonIndex].length) {
        continue
      }

      seasonEpisodes.splice(seasonIndex, 1)
      seasons.splice(seasonIndex, 1)
    }
  })()

  const handleScroll = () => {
    const yTrigger = 10

    window.scroll.on("scroll", value => {
      const scrollYOffset = value.scroll.y

      if (!pageScrolled && scrollYOffset > yTrigger) {
        header.setAttribute("scrolled", true)
        pageScrolled = true
      }

      if (pageScrolled && scrollYOffset < yTrigger) {
        header.setAttribute("scrolled", false)
        pageScrolled = false
      }
    })
  }

  const handleKeyUp = e => {
    switch (e.keyCode) {
      case 37:
        sliderRefs.current[activeSeasonRef.current].slickPrev()
        break
      case 39:
        sliderRefs.current[activeSeasonRef.current].slickNext()
        break

      default:
        break
    }
  }

  const showSeason = () => {
    gsap.to(seasonRefs.current[activeSeason], {
      opacity: 1,
      duration: 0.3,
      ease: "power3.out",
    })

    gsap.fromTo(
      episodeRefs.current[activeSeason],
      {
        opacity: 0,
        yPercent: 15,
      },
      {
        opacity: 1,
        yPercent: 0,
        stagger: 0.05,
        duration: 0.5,
        delay: 0.3,
        ease: "power2.inOut",
      }
    )
  }

  const hideSeason = seasonIndex => {
    gsap.to(seasonRefs.current[activeSeason], {
      opacity: 0,
      duration: 0.2,
      ease: "power3.in",
      onComplete: () => {
        setActiveSeason(seasonIndex)

        if (tabsAreSticky) {
          window.scrollTo(0, episodesContainerRef.current.offsetTop)
        }

        if (window.innerWidth > sliderInitBreakpoint) {
          sliderRefs.current.forEach(sliderRef => {
            sliderRef.slickGoTo(0)
          })
        }
      },
    })

    gsap.to(episodeRefs.current[activeSeason], {
      opacity: 0,
      ease: "power3.in",
    })
  }

  const updateSeason = seasonIndex => {
    if (seasonIndex !== activeSeason) {
      setActiveSeasonTab(seasonIndex)
      hideSeason(seasonIndex)
    }
  }

  const resizeEpisodeVideos = () => {
    const videos = document.querySelectorAll(
      "[data-episode-carousel-item] [data-video]"
    )

    videos.forEach(video => {
      maintainAspectRatio(video, 1.78)
    })
  }

  const sliderSettings = {
    infinite: false,
    slidesToShow: 3.25,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    draggable: true,
    onInit: () => resizeEpisodeVideos(),
    onReInit: () => resizeEpisodeVideos(),
    responsive: [
      {
        breakpoint: 1799,
        settings: {
          infinite: false,
          slidesToShow: 2.25,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: true,
          draggable: true,
        },
      },
      {
        breakpoint: sliderInitBreakpoint,
        settings: "unslick",
      },
    ],
  }

  const handleSliderMouseDown = e => {
    setMouseCoords({
      x: e.pageX,
      y: e.pageY,
    })
  }

  const fixTabsOnScroll = () => {
    const tabsOffset = episodesContainerRef.current.getBoundingClientRect()

    if (tabsOffset.top <= 10 && !fixTabsRef.current) {
      setTabsAreSticky(true)
      fixTabsRef.current = true
    } else if (tabsOffset.top > 10 && fixTabsRef.current) {
      setTabsAreSticky(false)
      fixTabsRef.current = false
    }

    scrollTicking = false
  }

  const onScroll = () => {
    if (!scrollTicking) {
      scrollRAF = window.requestAnimationFrame(fixTabsOnScroll)
    }
    scrollTicking = true
  }

  useEffect(() => {
    header = document.getElementById("header")

    gsap.to(episodesContainerRef.current, {
      opacity: 1,
      duration: 1,
      delay: 0.5,
      ease: "power3.inOut",
    })

    setTimeout(() => {
      if (!isMobile && window.scroll.update) {
        handleScroll()
        window.scroll.update()
      }
    }, 200)

    if (isMobile) {
      window.addEventListener("scroll", onScroll)
    }

    window.addEventListener("keyup", handleKeyUp)

    return () => {
      if (isMobile) {
        window.removeEventListener("scroll", onScroll)

        if (scrollRAF) {
          window.cancelAnimationFrame(scrollRAF)
        }
      }

      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (!isMobile && window.scroll.update) {
        window.scroll.update()
      }
    }, 200)

    gsap.to(tabsActiveBarRef.current, {
      xPercent: activeSeason * 100,
      opacity: 1,
      duration: 0.2,
      ease: "expo.inOut",
    })

    showSeason()
  }, [activeSeason])

  return (
    <>
      <ScrollSection>
        <section
          className={`${styles.episodes} ${
            tabsAreSticky ? styles.tabsAreSticky : ""
          }`}
        >
          <div
            className={`${styles.episodesContainer} container`}
            ref={episodesContainerRef}
          >
            <div className={styles.tabsWrapper}>
              <ul className={styles.tabs}>
                {seasons.map((seasonValue, seasonIndex) => (
                  <li
                    className={styles.tabsItem}
                    key={`seasons-${seasonValue}`}
                  >
                    <button
                      className={`${styles.tabsButton} ${
                        seasonIndex === activeSeasonTab ? styles.active : ""
                      }`}
                      onClick={() => updateSeason(seasonIndex)}
                    >
                      <span>{localiseString("Season")}</span> {seasonValue}
                    </button>
                  </li>
                ))}
                {bonus.length > 0 && (
                  <li className={styles.tabsItem} key={`seasons-bonus`}>
                    <button
                      className={`${styles.tabsButton} ${
                        seasonsLength === activeSeasonTab ? styles.active : ""
                      }`}
                      onClick={() => updateSeason(seasonsLength)}
                    >
                      Bonus
                    </button>
                  </li>
                )}
              </ul>
              <div
                className={styles.tabsActiveBar}
                data-season-count={seasons.length + (bonus.length > 0 ? 1 : 0)}
                ref={tabsActiveBarRef}
              ></div>
            </div>

            <div className={styles.carousel}>
              {seasonEpisodes.map((season, seasonIndex) => (
                <div
                  className={`${styles.carouselRow} ${
                    seasonIndex === activeSeason ? styles.active : ""
                  }`}
                  key={`season-${seasonIndex}`}
                  ref={ref => (seasonRefs.current[seasonIndex] = ref)}
                  onMouseDown={handleSliderMouseDown}
                >
                  <Slider
                    {...sliderSettings}
                    ref={ref => (sliderRefs.current[seasonIndex] = ref)}
                  >
                    {season.map((ep, epIndex) => (
                      <EpisodesCarouselItem
                        ref={ref =>
                          (episodeRefs.current[seasonIndex][epIndex] = ref)
                        }
                        key={ep.slug}
                        seasonNumber={ep.seasonNumber}
                        episodeNumber={ep.episodeNumber}
                        scheduledDateString={ep.scheduledDateString}
                        scheduledDate={ep.episodeScheduler}
                        title={ep.title}
                        description={ep.seoDescription.seoDescription}
                        runtime={ep.videoRuntime}
                        episodeSlug={ep.slug}
                        image={ep.heroPosterImage.fluid}
                        imageDescription={ep.heroPosterImage.description}
                        videoId={ep.heroPosterLoop}
                        mouseCoords={mouseCoords}
                        handleScheduledClick={() => setShowNotify(true)}
                      />
                    ))}
                  </Slider>
                </div>
              ))}
              {bonus.length > 0 && (
                <div
                  className={`${styles.carouselRow} ${
                    seasonsLength === activeSeason ? styles.active : ""
                  }`}
                  key={`season-${seasonsLength}`}
                  ref={ref => (seasonRefs.current[seasonsLength] = ref)}
                  onMouseDown={handleSliderMouseDown}
                >
                  <Slider
                    {...sliderSettings}
                    ref={ref => (sliderRefs.current[seasonsLength] = ref)}
                  >
                    {bonus.map((ep, epIndex) => (
                      <EpisodesCarouselItem
                        ref={ref =>
                          (episodeRefs.current[seasonsLength][epIndex] = ref)
                        }
                        key={ep.slug}
                        episodeNumber={ep.episodeNumber}
                        scheduledDateString={ep.scheduledDateString}
                        scheduledDate={ep.episodeScheduler}
                        title={ep.title}
                        description={ep.seoDescription.seoDescription}
                        runtime={ep.videoRuntime}
                        episodeSlug={ep.slug}
                        image={ep.heroPosterImage.fluid}
                        imageDescription={ep.heroPosterImage.description}
                        videoId={ep.heroPosterLoop}
                        mouseCoords={mouseCoords}
                        handleScheduledClick={() => setShowNotify(true)}
                      />
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </section>
      </ScrollSection>
      <Portal>
        <Notify open={showNotify} closeEvent={() => setShowNotify(false)} />
      </Portal>
    </>
  )
}

export default EpisodesCarousel
