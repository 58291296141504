// Core
import React, { useEffect } from "react"

// Components
import EpisodesCarousel from "../../components/episodesCarousel"
import SEO from "../../components/seo"
import { maskIn } from "../../utils/masks"
import { localiseString } from "../../utils/localiseString"

const IndexPage = props => {
  useEffect(() => {
    maskIn()
  }, [])

  return (
    <React.Fragment>
      <SEO
        title={localiseString("Episodes")}
        description={localiseString(
          "Discover released performances, explore upcoming episodes, and sign up for email notifications when new ones drop."
        )}
        localImage="/images/share-episodes.jpg"
        pathname={localiseString("episodes")}
      />
      <EpisodesCarousel
        episodes={props.data.seasonEpisodes.nodes}
        bonus={props.data.bonusEpisodes.nodes}
      />
    </React.Fragment>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    seasonEpisodes: allContentfulEpisode(
      filter: { slug: { ne: "sample" }, seasonNumber: { ne: 0 } }
      sort: { fields: [seasonNumber, episodeNumber], order: DESC }
    ) {
      nodes {
        slug
        title
        seasonNumber
        episodeNumber
        heroPosterLoop
        scheduledDateString
        episodeScheduler {
          title
        }
        seoDescription {
          seoDescription
        }
        videoRuntime
        fallbackYouTubeUrl
        expired
        episodeVimeoLink
        heroPosterImage {
          fluid(maxWidth: 1200, maxHeight: 675) {
            ...GatsbyContentfulFluid
          }
          description
        }
      }
    }
    bonusEpisodes: allContentfulEpisode(
      filter: { slug: { ne: "sample" }, seasonNumber: { eq: 0 } }
      sort: { fields: [seasonNumber, episodeNumber] }
    ) {
      nodes {
        slug
        title
        seasonNumber
        episodeNumber
        heroPosterLoop
        scheduledDateString
        episodeScheduler {
          title
        }
        seoDescription {
          seoDescription
        }
        videoRuntime
        fallbackYouTubeUrl
        expired
        episodeVimeoLink
        heroPosterImage {
          fluid(maxWidth: 1200, maxHeight: 675) {
            ...GatsbyContentfulFluid
          }
          description
        }
      }
    }
  }
`
