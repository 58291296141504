import React, { forwardRef, useState, useRef } from "react"
import gsap from "gsap"

import Img from "gatsby-image"
import Video from "../video"

import styles from "./episodesCarouselItem.module.scss"
import { maskOut } from "../../utils/masks"
import { navigate } from "gatsby"
import { localiseString } from "../../utils/localiseString"

import { SlideMaskText } from "../slideMaskText"

const EpisodesCarouselItem = forwardRef(
  (
    {
      seasonNumber,
      episodeNumber,
      scheduledDateString,
      scheduledDate,
      title,
      description,
      runtime,
      episodeSlug,
      image,
      imageDescription,
      videoId,
      mouseCoords,
      handleScheduledClick,
    },
    ref
  ) => {
    const [playVideo, setPlayVideo] = useState(false)
    const episodeVideo = useRef(null)
    const isLive = scheduledDate

    const showVideo = () => {
      if (!isLive) return

      setPlayVideo(true)
      gsap.to(episodeVideo.current, {
        opacity: 1,
        duration: 0.15,
        delay: 0.1,
        ease: "power2.out",
      })
    }

    const hideVideo = () => {
      if (!isLive) return

      gsap.to(episodeVideo.current, {
        opacity: 0,
        duration: 0.15,
        ease: "power2.in",
        onComplete: () => {
          setPlayVideo(false)
        },
      })
    }

    const handleItemClick = e => {
      if (
        e.pageX < mouseCoords.x - 5 ||
        (e.pageX > mouseCoords.x + 5 && e.pageY < mouseCoords.y - 5) ||
        e.pageY > mouseCoords.y + 5
      ) {
        e.stopPropagation()
      }
    }

    const navigateTo = () => {
      maskOut()
      setTimeout(() => {
        navigate(`/${episodeSlug}`)
      }, 1250)
    }

    return (
      <div
        ref={ref}
        className={`${styles.episodeBlock} ${isLive ? styles.isLive : ""}`}
        onMouseEnter={showVideo}
        onMouseLeave={hideVideo}
        data-episode-carousel-item
      >
        <div
          className={`${styles.episodeBlockImage} ${
            !isLive ? styles.isScheduled : ""
          }`}
        >
          <Img alt={imageDescription} fluid={image} />

          {isLive && videoId && (
            <div className={styles.episodeVideo} ref={episodeVideo}>
              <Video
                vidID={videoId}
                autoplay
                fill
                stretch
                muted
                playState={playVideo}
              />
            </div>
          )}
        </div>

        {seasonNumber ? (
          <p className={styles.episodeBlockSeason}>
            <strong>
              {localiseString("Season")} {seasonNumber}
            </strong>{" "}
            - {localiseString("EP")}
            {episodeNumber}
          </p>
        ) : (
          <p className={styles.episodeBlockSeason}>
            <strong>{localiseString("Bonus")}</strong> - {localiseString("EP")}
            {episodeNumber}
          </p>
        )}

        <p className={styles.episodeBlockTitle}>{title}</p>

        {description && (
          <p className={styles.episodeBlockDescription}>{description}</p>
        )}

        {runtime && (
          <p className={styles.episodeBlockRuntime}>
            {localiseString("Run time")} - {runtime} {localiseString("mins")}
          </p>
        )}

        <div className={styles.episodeBlockBtnWrapper}>
          {isLive ? (
            <button
              className={`${styles.episodeBlockBtn} button button--outline`}
              onClick={navigateTo}
              onClickCapture={handleItemClick}
            >
              <SlideMaskText text={localiseString("Explore")} />
            </button>
          ) : (
            <button
              className={`${styles.episodeBlockBtn} ${styles.isDisabled} button button--outline`}
              onClick={handleScheduledClick}
              onClickCapture={handleItemClick}
            >
              <SlideMaskText
                text={`${localiseString("Launches")} - ${scheduledDateString}`}
              />
            </button>
          )}
        </div>
      </div>
    )
  }
)

export { EpisodesCarouselItem }
